import { authHeader, config } from '../_helpers';
import { userService } from '../_services/user.service'

export const recordsService = {
    getRecords,
    getRecordsSverka,
    updateLeadInfo,
    setVisibilityRecord,
    getHiddens
};

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
function handleResponseSverka(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return aggregateSverka(data);
    });
}
function handleResponseGetHiddens(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();

                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
function handleResponseSetVisibility(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function getRecords() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    var url = config.apiUrl ? new URL(`https://${config.apiUrl}/records/ByDate`) : new URL('records/ByDate', `http://${window.location.host}`)

    var params = { dtFrom: '2019-10-01', dtTo: '2019-11-01' }

    url.search = new URLSearchParams(params).toString();

    return fetch(url, requestOptions).then(handleResponse);
}


function getRecordsSverka() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };


    var url = new URL(`${config.apiUrl}/records/SverkaByDate`, `${window.location.protocol}//${window.location.host}`);

    var params = { dtFrom: '2019-10-01', dtTo: '2019-11-01' }

    url.search = new URLSearchParams(params).toString();

    return fetch(url, requestOptions).then(handleResponseSverka);
}

function aggregateSverka(items) {
    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);


            //console.log('x', x, rv);
            return rv;
        }, {});
    };

    const groupByTwo = (xs, key1, key2) => {
        //console.log('xs, key1, key2', xs, key1, key2);
        return xs.reduce(function (rv, x) {
            const k = x[key2] + '-' + x[key1];
            (rv[k] = rv[k] || []).push(x);
            //console.log('x2', x, rv);
            return rv;
        }, {});
    };
    //console.log('groupBy(items, \'phone\')', groupBy(items, 'phone'))

    //console.log('groupByTwo(items, \'phone\',\'projectName\')', groupByTwo(items, 'phone', 'projectId'))
    const res = Object.values(groupByTwo(items, 'phone', 'projectId'))
        .map((x, idx, arr) => {
      //      console.log('x=', x);
            return {

                phone: x[0].phone,
                projectName: x[0].projectName,
                callDate: x[0].callDate,
                comment: x[0].comment,
                callId: x[0].callId,
                talks: x.map((i) => {
                    return {
                        records: i.records,
                        sessionId: i.sessionId,
                        startTime: i.startTime,
                        finishTime: i.finishTime,
                    }
                }),

                commentImetik: x[0].commentImetik,
                commentPartner: x[0].commentPartner,
                status: x[0].status,
                isShipped: x[0].isShipped
            }
        })
    return res;

}


function updateLeadInfo(leadInfo) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(leadInfo)
    };
    var url = new URL(`${config.apiUrl}/records/SverkaUpdateLead`, `${window.location.protocol}//${window.location.host}`);

    return fetch(url, requestOptions);
}

function setVisibilityRecord(visibility) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(visibility)
    };

    var url = new URL(`${config.apiUrl}/records/SetVisibility`, `${window.location.protocol}//${window.location.host}`);

    return fetch(url, requestOptions).then(handleResponseSetVisibility);;
}

function getHiddens(sessionId) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    var params = { sessionId: sessionId }

    var url = new URL(`${config.apiUrl}/records/getHiddens`, `${window.location.protocol}//${window.location.host}`);
    url.search = new URLSearchParams(params).toString();

    return fetch(url, requestOptions).then(handleResponseGetHiddens);
}