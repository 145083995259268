import { subRowConstants } from '../_constants';

export function subRow(state = {}, action) {
    switch (action.type) {
        case subRowConstants.GETHIDDENS_SUCCESS:
            let hiddens = action.hiddens;
            return {
                ...state,
                loading: false,
                error: null,
                hiddens: {...state.hiddens, [action.sessionId]: hiddens }
            };
        case subRowConstants.GETHIDDENS_REQUEST:
            return {
                ...state,
                loading: false,
                error: null
            };
        case subRowConstants.GETHIDDENS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                hiddens: { [action.sessionId]: hiddens }
                
            };
        case subRowConstants.SETHIDDENS_SUCCESS:
            return {
                ...state,
                result: 'success',
                loading: false
            };
        case subRowConstants.SETHIDDENS_REQUEST:
            return {
                ...state,
                result: 'success',
                loading: false
            };
        case subRowConstants.SETHIDDENS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}