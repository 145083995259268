import { recordsConstants } from '../_constants';

export function records(state = {}, action) {
    switch (action.type) {
        case recordsConstants.GETRECORDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case recordsConstants.GETRECORDS_SUCCESS:
            return {
                items: action.records
            };
        case recordsConstants.GETRECORDS_FAILURE:
            return {
                error: action.error
            };
        case recordsConstants.SVERKA_REQUEST:
            return {
                ...state,
                loading: true
            };
        case recordsConstants.SVERKA_SUCCESS:
            return {
                items: action.recordsSverka
            };
        case recordsConstants.SVERKA_FAILURE:
            return {
                error: action.error
            };
        case recordsConstants.SVERKA_SAVE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case recordsConstants.SVERKA_SAVE_SUCCESS:
            return {
                ...state,
                result: 'success'
            };
        case recordsConstants.SVERKA_SAVE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}