import { subRowConstants } from '../_constants';
import { recordsService } from '../_services';
import { alertActions } from '.';

export const subRowActions = {
    getHiddens, setVisibility
};

function getHiddens(sessionId) {
    return dispatch => {
        dispatch(request({}));

        recordsService.getHiddens(sessionId)
            .then(
                hiddens => {
                    dispatch(success(sessionId, hiddens));
                },
                error => {
                    dispatch(failure('sr:' +error.toString()));
                    dispatch(alertActions.error('sr:'+error.toString()));
                }
            );
    };

    function request(user) { return { type: subRowConstants.GETHIDDENS_REQUEST, user } }
    function success(sessionId, hiddens) { return { type: subRowConstants.GETHIDDENS_SUCCESS, sessionId: sessionId, hiddens: hiddens  } }
    function failure(error) { return { type: subRowConstants.GETHIDDENS_FAILURE, error } }
}
function setVisibility(sessionId, recordId, isVisible) {
    return dispatch => {
        dispatch(request({}));

        recordsService.setVisibilityRecord({
            sessionId: sessionId,
            recordId: recordId,
            visibility: isVisible
        })
            .then(
                hiddens => {
                   
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            )
    };

    function request(user) { return { type: subRowConstants.SETHIDDENS_REQUEST, user } }
    function success() { return { type: subRowConstants.SETHIDDENS_SUCCESS,  } }
    function failure(error) { return { type: subRowConstants.SETHIDDENS_FAILURE, error } }
}