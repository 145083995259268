import { authHeader, config } from '../_helpers';
import { userService } from '../_services/user.service'

export const planService = {
    getPlans, updatePlan
};

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponseUpdatePlan(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function getPlans(month, projectName) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    var url = new URL(`${config.apiUrl}/plan`, `${window.location.protocol}//${window.location.host}`);

    var params = { month: month }
    if (projectName)
        params.projectName = projectName;


    url.search = new URLSearchParams(params).toString();

    return fetch(url, requestOptions).then(handleResponse);
}


function updatePlan(plan) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(plan)
    };

    var url = new URL(`${config.apiUrl}/plan`, `${window.location.protocol}//${window.location.host}`);

    return fetch(url, requestOptions).then(handleResponseUpdatePlan);
}
