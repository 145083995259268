import { planConstants } from '../_constants';

export function plans(state = {}, action) {
    switch (action.type) {
        case planConstants.SETPLAN_SUCCESS:
            let planResult = action.plan;
            return {
                month: planResult.month,
                projectName: planResult.projectName,
                planCurr: planResult.planCurr,
                planPrev: planResult.planPrev,
                loading: false
            };
        case planConstants.SETPLAN_REQUEST:
            return {
                loading: true
            };
        case planConstants.SETPLAN_FAILURE:
            return {
                loading: false,
                error: action.error

            };
        case planConstants.GETPLAN_SUCCESS:
            return {
                items: action.items,
                loading: false
            };
        case planConstants.GETPLAN_REQUEST:
            return {
                loading: true
            };
        case planConstants.GETPLAN_FAILURE:
            return {
                loading: false,
                error: action.error

            };
        default:
            return state
    }
}