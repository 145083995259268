import React from 'react'
import { Table } from 'react-bootstrap'
import { ModalPlan } from '../ModalPlan'
import { planActions } from '../_actions'
import { connect } from 'react-redux';
import { formatDateWithoutTime } from '../_helpers'

import './Aggregate.css'

class Aggregate extends React.Component {

    constructor(props) {
        super(props);

        this.setModalPlan = this.setModalPlan.bind(this);

        this.state = {
            modalShow: false,
            items: props.items,
            isAdmin: props.isAdmin,
            plan: {},
            currentMonth: null
        };
    }
    componentDidMount() {
        if (this.props.month) {
            this.props.getPlan(formatDateWithoutTime(new Date(this.props.month)));
            this.setState({ currentMonth: this.props.month });
        }
    }

    render() {
        if (!this.props.items) return null;
        if ((this.props.month) && (!this.props.month.isSame(this.state.currentMonth))) {
            //console.log('this.state.currentMonth != this.props.month'
            //    , this.state.currentMonth, this.props.month);
            this.props.getPlan(formatDateWithoutTime(new Date(this.props.month)));
            this.setState({ currentMonth: this.props.month });
        }
        const projects = this.props.items.map(x => x.projectName)
            .filter((v, i, a) => a.indexOf(v) === i);
        const agg = projects.map(x => {
            const aggIn = aggregateByStatus(this.props.items.filter(f => f.projectName === x));

            const sum = aggIn.reduce(
                (accumulator, currentValue) => { return accumulator + currentValue.cnt }
                , 0);

            return {
                project: x, stat: aggIn.concat([{ status: 'Всего', projectName: x, cnt: sum }])
            }
        }
        );
        //console.log('render', this.props.month)
        return (
            <div>
                <Table bordered size="sm">
                    <tbody>
                        {agg.sort(function (a, b) {
                            if (a.project > b.project) {
                                return 1;
                            }
                            if (a.project < b.project) {
                                return -1;
                            }
                            // a должно быть равным b
                            return 0;
                        }
                        ).map(i =>
                            i.stat.map(s =>
                                <tr key={s.status} style={{
                                    background: getBackgroundColor(s.status)
                                }}>
                                    <td className={s.status === 'Всего' ? 'td_bold' : ''}>
                                        {i.project ? i.project : 'Неизвестно'}</td>
                                    <td className={s.status === 'Всего' ? 'td_bold' : ''}>{s.status ? s.status : 'Неизвестно'}</td>
                                    <td className={s.status === 'Всего' ? 'td_bold' : ''}>{s.cnt}</td>
                                    <td className={s.status === 'Всего' ? 'td_bold' : ''}>
                                        {this.getPlanValues(i.project).planCurrent ? this.getPlanValues(i.project).planCurrent : 0 +
                                            this.getPlanValues(i.project).planPrev ? this.getPlanValues(i.project).planPrev : 0}</td>
                                    <td className={s.status === 'Всего' ? 'td_bold' : ''}>
                                        <i className="fa fa-edit fa-xs"
                                            onClick={() => {
                                                this.setModalPlan(true, { projectName: s.projectName });
                                            }}></i>
                                    </td>

                                </tr>

                            )
                        )}
                    </tbody>
                </Table>
                {this.state.isAdmin && <ModalPlan
                    show={this.state.modalShow}
                    onCancel={() => this.setModalPlan(false)}
                    onHide={() => this.setModalPlan(false)}
                    onsave={(p) => {
                        this.props.updatePlan(p);
                        this.setModalPlan(false);
                    }}
                    editpermissions={{ isAdmin: this.state.isAdmin }}
                    plan={this.getPlanValues(this.state.plan ? this.state.plan.projectName : undefined)}
                />}
            </div>
        )

        function getBackgroundColor(status) {
            switch (status) {
                case 'Нецелевой': return 'lightsalmon';
                case 'Спорный': return 'wheat'
                case 'Целевой': return 'lightgreen'
                default: return ''
            }
        }

        function aggregateByStatus(items) {
            const groupBy = (xs, key) => {
                return xs.reduce(function (rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };
            const res = Object.values(groupBy(items, 'status'))
                .map((x, idx, arr) => {
                    return {
                        status: x[0].status,
                        projectName: x[0].projectName,
                        cnt: x.length
                    }
                });
            return res;

        }
    }

    getPlanValues(project) {
        let res = {};
        if ((project && this.props.plans.items))
            res = this.props.plans.items.filter(x => x.projectName === project);
        if (res.length > 0)
            res = {
                month: this.props.month.toDate(),
                projectName: this.state.plan ? this.state.plan.projectName : undefined,
                planCurrent: res[0].planCurrent, planPrev: res[0].planPrev
            }
        else res = {
            month: this.props.month.toDate(),
            projectName: this.state.plan ? this.state.plan.projectName : undefined
        }

        return res;
    }

    setModalPlan(isShown, plan) {
        this.setState({
            modalShow: isShown//, planCurrent, planPrev, month
            , plan: plan
        });
    }
}

function mapState(state) {
    const { plans } = state;
    return { plans };
}

const actionCreators = {
    updatePlan: planActions.updatePlan,
    getPlan: planActions.getPlans

}

const connectedAggregate = connect(mapState, actionCreators)(Aggregate);
export { connectedAggregate as Aggregate };