import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { records } from './records.reducer';
import { subRow } from './sub-row.reducer';
import { plans } from './plans.reducer';

const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    alert,
    records,
    subRow,
    plans

});

export default rootReducer;