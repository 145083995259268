import { recordsConstants } from '../_constants';
import { recordsService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';

export const recordsActions = {
    getRecords, getRecordsSverka, updateRecordsSverka
};

function getRecords() {
    return dispatch => {
        dispatch(request({}));

        recordsService.getRecords()
            .then(
                records => {
                    dispatch(success(records));
                    history.push('/');
                },
                error => {
                    dispatch(failure('r1:'+error.toString()));
                    dispatch(alertActions.error('r1:' +error.toString()));
                }
            );
    };

    function request(user) { return { type: recordsConstants.GETRECORDS_REQUEST, user } }
    function success(records) { return { type: recordsConstants.GETRECORDS_SUCCESS, records } }
    function failure(error) { return { type: recordsConstants.GETRECORDS_FAILURE, error } }
}


function getRecordsSverka() {
    return dispatch => {
        dispatch(request({}));

        recordsService.getRecordsSverka()
            .then(
                recordsSverka => {
                    dispatch(success(recordsSverka));
                    history.push('/');
                }
                ,
                error => {
                    dispatch(failure('r2:' +error.toString()));
                    dispatch(alertActions.error('r2:' +error.toString()));
                }
            );
    };

    function request(user) { return { type: recordsConstants.SVERKA_REQUEST, user } }
    function success(recordsSverka) { return { type: recordsConstants.SVERKA_SUCCESS, recordsSverka } }
    function failure(error) { return { type: recordsConstants.SVERKA_FAILURE, error } }
}

function updateRecordsSverka(leadUpdateInfo) {
    return dispatch => {
        dispatch(request({}));
        recordsService.updateLeadInfo(leadUpdateInfo)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure('r3:' +error.toString()));
                    dispatch(alertActions.error('r3:' +error.toString()));
                }
        )
            .then(() => {
                //dispatch(getRecordsSverka());
            });
    };

    function request(leadUpdateInfo) { return { type: recordsConstants.SVERKA_SAVE_REQUEST, leadUpdateInfo } }
    function success(result) { return { type: recordsConstants.SVERKA_SAVE_SUCCESS, result } }
    function failure(error) { return { type: recordsConstants.SVERKA_SAVE_FAILURE, error } }
}
