import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'

import { formatDate } from '../_helpers'

export function ModalSverka(props) {
    const statusList = ['Целевой', 'Спорный', 'Нецелевой', 'Закрыто']
    const { onSave, editpermissions, lead } = props;

    const [newLead, setLead] = useState({
        commentImetik: lead.commentImetik,
        commentPartner: lead.commentPartner,
        status: lead.status,
        isShipped: lead.isShipped
    });

    const handleChange = val => {
        console.log(val.target, newLead);
        if (val.target.name === 'isLead') {
            if ((val.target.value === newLead.leadStatus) && (newLead.leadStatus !== 0)) { val.target.value = 0; }
            setLead({ ...newLead, status: val.target.value });
        }
        else if (val.target.name === 'isShipped') {
            setLead({ ...newLead, isShipped: val.target.value === 'true' });
        }
        console.log('after', val.target,newLead);
    };

    const getButtonStyle = (e) => {
        let res;
        const currentStatus = newLead.status ? statusList[newLead.status - 1] : lead.status

        if (statusList[e - 1] === currentStatus)
            switch (e) {
                case 1: res = 'lightgreen'; break;
                case 2: res = 'wheat'; break;
                case 3: res = 'lightsalmon'; break;
                default: return ''
            }
        else res = 'gainsboro'
        return { background: res, color: 'black' };
    }

    const getShippedButtonStyle = (e) => {
        let res;
        const currentProp = newLead.isShipped === undefined ? lead.isShipped : newLead.isShipped;
        //console.log(typeof newLead.isShipped, typeof lead.isShipped, newLead.isShipped, lead.isShipped);
        //console.log(typeof currentProp, typeof e, currentProp, e, currentProp === e);
        if (currentProp === e)
            res = e ? 'lightgreen' : 'wheat'
        else
            res = 'gainsboro'
        //console.log(e, res);
        return { background: res, color: 'black' };
    }

    //console.log(lead, newLead, lead.isShipped);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Лид:&nbsp;{lead.phone} Проект:&nbsp;{lead.projectName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} controlId="formPlaintextOperatorComment">
                        <Form.Label column sm="4">
                            Комментарий оператора
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control plaintext readOnly defaultValue={lead.comment} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextCallDate">
                        <Form.Label column sm="4">
                            Дата последнего звонка
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control plaintext readOnly defaultValue={formatDate(new Date(lead.callDate))} />
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="formCommentImetik">
                        <Form.Label>Комментарий АЙМЕТИК</Form.Label>
                        <Form.Control as="textarea"
                            defaultValue={lead.commentImetik}
                            onChange={(e) => setLead({ ...newLead, commentImetik: e.target.value })}
                            plaintext readOnly={!editpermissions.isAdmin}
                        />
                    </Form.Group>
                    <Form.Group controlId="formCommentPartner">
                        <Form.Label >Комментарий Заказчика</Form.Label>
                        <Form.Control as="textarea"
                            value={newLead.commentPartner}
                            defaultValue={lead.commentPartner}
                            onChange={(e) => setLead({ ...newLead, commentPartner: e.target.value })}
                            plaintext readOnly={editpermissions.isAdmin}
                        />
                    </Form.Group>
                    <Form.Group controlId="formStatus">
                        <Form.Label>Статус</Form.Label>
                        <div>
                            <ToggleButtonGroup name="isLead" defaultValue={lead.leadStatus}
                                onClick={handleChange}>
                                <ToggleButton style={getButtonStyle(1)} value={1} name="lead">Целевой</ToggleButton>
                                <ToggleButton style={getButtonStyle(2)} value={2} name="unknown">Спорный</ToggleButton>
                                <ToggleButton style={getButtonStyle(3)} value={3} name="invalid">Нецелевой</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    {editpermissions.isAdmin &&
                        <Form.Group controlId="formShipped">
                            <div>
                                <ToggleButtonGroup name="isShipped" defaultValue={lead.isShipped}
                                    onClick={handleChange}>
                                    <ToggleButton style={getShippedButtonStyle(true)} value={true} name="lead">Отгружен</ToggleButton>
                                    <ToggleButton style={getShippedButtonStyle(false)} value={false} name="unknown">Неотгружен</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </Form.Group>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    onSave({ ...newLead, callId: lead.callId });
                    setLead({});
                }
                }>Save</Button>
                <Button variant="danger" onClick={() => {
                    props.onCancel();
                    setLead({});
                }
                }>Cancel</Button>
            </Modal.Footer>
        </Modal >
    );
}
