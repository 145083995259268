export function fancyTimeFormat(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

export function formatDate(dt) {
    var mm = dt.getMonth() + 1; // getMonth() is zero-based
    var dd = dt.getDate();
    var hh = dt.getHours();
    var minutes = dt.getMinutes();

    return `${dt.getFullYear()}-${(mm > 9 ? '' : '0') + mm}-${(dd > 9 ? '' : '0') + dd} ${(hh > 9 ? '' : '0') + hh}:${(minutes > 9 ? '' : '0') + minutes}`

};

export function formatDateWithoutTime(dt) {
    var mm = dt.getMonth() + 1; // getMonth() is zero-based
    var dd = dt.getDate();

    return `${dt.getFullYear()}-${(mm > 9 ? '' : '0') + mm}-${(dd > 9 ? '' : '0') + dd}`

};