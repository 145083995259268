export const recordsConstants = {   

    GETRECORDS_REQUEST: 'RECORDS_GET_REQUEST',
    GETRECORDS_SUCCESS: 'RECORDS_GET_SUCCESS',
    GETRECORDS_FAILURE: 'RECORDS_GET_FAILURE',
    SVERKA_REQUEST: 'SVERKA_GET_REQUEST',
    SVERKA_SUCCESS: 'SVERKA_GET_SUCCESS',
    SVERKA_FAILURE: 'SVERKA_GET_FAILURE',
    SVERKA_SAVE_REQUEST: 'SVERKA_SAVE_REQUEST',
    SVERKA_SAVE_SUCCESS: 'SVERKA_SAVE_SUCCESS',
    SVERKA_SAVE_FAILURE: 'SVERKA_SAVE_FAILURE'
       
};
