import React, { useState } from 'react';
import { formatDateWithoutTime } from '../_helpers'
import { Modal, Button, Form } from 'react-bootstrap'

export function ModalPlan(props) {
    const { onsave, plan } = props;

    const [newPlan, setPlan] = useState({});

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    План:&nbsp;{formatDateWithoutTime(new Date(plan.month))}<br />
                    Проект:&nbsp;{plan.projectName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formModalPlanPrev">
                        <Form.Label>План текущего месяца</Form.Label>
                        <Form.Control type="text" placeholder="0" defaultValue={plan.planCurrent}
                            onChange={(e) => setPlan({ ...newPlan, planCurrent: parseInt(e.target.value) })} />
                    </Form.Group>
                    <Form.Group controlId="formModalPlanCurr">
                        <Form.Label>План с прошлого месяца</Form.Label>
                        <Form.Control type="text" placeholder="0" defaultValue={plan.planPrev}
                            onChange={(e) => setPlan({ ...newPlan, planPrev: parseInt(e.target.value) })} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    //console.log('p', plan);
                    //console.log('fffp', { ...plan, ...newPlan });
                    onsave({ ...plan, ...newPlan });
                    setPlan({});
                }
                }>Save</Button>
                <Button variant="danger" onClick={() => {
                    props.onCancel();
                    setPlan({});
                }
                }>Cancel</Button>
            </Modal.Footer>
        </Modal >
    );

}
