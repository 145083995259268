import { planConstants } from '../_constants';
import { planService } from '../_services';
import { alertActions } from '.';

export const planActions = {
    updatePlan, getPlans
};

function updatePlan(plan) {
    return dispatch => {
        dispatch(request({}));

        planService.updatePlan(plan)
            .then(
                res => {
                    dispatch(success(res, plan));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(plan) { return { type: planConstants.SETPLAN_REQUEST, plan } }
    function success(res, plan) { return { type: planConstants.SETPLAN_SUCCESS, res, plan } }
    function failure(error) { return { type: planConstants.SETPLAN_FAILURE, error } }
}

function getPlans(month, projectName = null) {
    return dispatch => {
        dispatch(request({}));

        planService.getPlans(month, projectName)
            .then(
                res => {
                    console.log('plans success', res);
                    dispatch(success(res));
                },
                error => {
                    console.log('plans error', error);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(plan) { return { type: planConstants.GETPLAN_REQUEST, plan } }
    function success(items) { return { type: planConstants.GETPLAN_SUCCESS, items } }
    function failure(error) { return { type: planConstants.GETPLAN_FAILURE, error } }
}