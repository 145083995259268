import React from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import { Navbar, Row, Col, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { recordsActions } from '../_actions';
import { SverkaSubRow } from '../SverkaSubRow'
import { SverkaSubRowBA } from '../SverkaSubRowBA'
import { ModalSverka } from '../ModalSverka'
import { Aggregate } from '../Aggregate'

import 'react-table/react-table.css'
import './Sverka.css'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { permissions } from '../_helpers'
import DatetimeRangePicker from '../ReactBootstrapDatetimeRangePicker';
import { formatDate } from '../_helpers'
import moment from 'moment';
import Loader from 'react-loader-spinner';

import XLSX from 'xlsx';

class Sverka extends React.Component {
    constructor(props) {
        super(props);
        this.handleEvent = this.handleEvent.bind(this);
        this.handleShowShippedChange = this.handleShowShippedChange.bind(this);
        this.state = {
            modalShow: false,
            lead: {},
            selected: -1,
            startDate: process.env.NODE_ENV === 'development' ? moment("01-02-2020", "DD-MM-YYYY") : moment().startOf('month'),
            endDate: process.env.NODE_ENV === 'development' ? moment("29-02-2020", "DD-MM-YYYY") : moment().endOf('month'),
            ranges: {
                'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'Этот месяц': [moment().startOf('month'), moment().endOf('month')]
            },
            showShipped: false,
        };
    }
    componentDidMount() {
        this.props.getRecords();

    }
    //TODO:filter projects
    //    getProjects = (recs) => {
    //        if (!recs) return [];
    //        return recs
    //            .map(x => x.projectName)
    //            .filter((v, i, a) => a.indexOf(v) === i);
    //    }
    //                            <ToggleButtonGroup name="projects">
    //    {this.getProjects(records.items).map(p =>
    //        <ToggleButton value={p}>{p}</ToggleButton>
    //    )}
    //</ToggleButtonGroup>
    setModalShow(isShown, lead = {}) {
        this.setState({ modalShow: isShown, lead: isShown ? lead : {} });
    }

    getBackgroundColor = (status) => {
        switch (status) {
            case 'Нецелевой': return 'lightsalmon';
            case 'Спорный': return 'wheat'
            case 'Целевой': return 'lightgreen'
            default: return ''
        }
    }

    getTrProps = (state, rowInfo, column, instance) => {

        if (typeof rowInfo !== "undefined") {
            return {
                className: rowInfo.original.isShipped ? 'text-muted' : '',
                style: {
                    background: this.getBackgroundColor(rowInfo.row.status)
                    , fontStyle: rowInfo.original.isShipped ? 'italic' : 'inherit'
                }
                ,
            }
        }
        else {
            return {

            }
        }
    }

    handleEvent(event, picker) {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
        });
    }

    getRecords() {

        console.log('getRecords', this.state.showShipped);
        if (!(this.props.records && this.props.records.items))
            return [];
        let res = this.props.records.items.filter(
            (e) => {
                return moment(e.callDate).isBetween(this.state.startDate, this.state.endDate);

            });

        res = res.filter(e => this.state.showShipped ? true : !e.isShipped)
        return res;
    }

    handleShowShippedChange(e, t) {
        this.setState({ showShipped: !this.state.showShipped });
        // console.log('handleShowShippedChange', e, t, this.state.showShipped );
    }


    render() {
        const columns = [
            {
                Header: 'Телефон',
                accessor: 'phone',
                width: 120,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["phone"] }),
                filterAll: true
            },
            {
                Header: '',
                width: 50,
                Cell: props =>
                    <button className="btn" onClick={
                        (e) => { this.setModalShow(true, props.original); }}>
                        <i className="fa fa-edit fa-lg"></i>
                    </button>
            },
            {
                Header: 'Проект',
                accessor: 'projectName',
                width: 150,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["projectName"] }),
                filterAll: true
            },
            {
                Header: 'Комментарий оператора',
                accessor: 'comment',
                width: 300,
                style: { 'whiteSpace': 'unset' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["comment"] }),
                filterAll: true
            },
            {
                Header: 'Время звонка',
                accessor: 'callDate',
                width: 150,
                style: { 'whiteSpace': 'unset' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["comment"] }),
                filterAll: true,
                Cell: row => (<span>{formatDate(new Date(row.value))}</span>)
            },
            {
                Header: 'Статус', accessor: 'status',
                width: 100,
                style: { 'whiteSpace': 'unset' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["status"] }),
                filterAll: true
            },
            {
                Header: 'Комментарий Айметик', accessor: 'commentImetik',
                width: 300,
                style: { 'whiteSpace': 'unset' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["commentImetik"] }),
                filterAll: true
            },
            {
                Header: 'Комментарий заказчика', accessor: 'commentPartner',
                width: 300,
                style: { 'whiteSpace': 'unset' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["commentPartner"] }),
                filterAll: true
            }
        ];
        const { user } = this.props;


        //console.log('this.state.startDate', this.state.startDate)
        let start = this.state.startDate.format('DD.MM.YYYY');
        let end = this.state.endDate.format('DD.MM.YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }


        let buttonStyle = {};
        return (<div>
            <Navbar>
                <Navbar.Brand href="#home"></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        Signed in as: {user.fullName}
                    </Navbar.Text>
                    &nbsp;&nbsp;
                        <Navbar.Text>
                        <a href="/logout">Logout</a>
                    </Navbar.Text>

                </Navbar.Collapse>
            </Navbar>
            <div>
                <Row>
                    <Col md={{ span: 9 }}>
                        <div style={{ display: "inline" }}>
                            <DatetimeRangePicker style={{ display: "inline" }}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                ranges={this.state.ranges}
                                onEvent={this.handleEvent}
                                callback={(start, end) => {
                                    //console.log('start', start.toDate());
                                    this.setState({ startDate: start, endDate: end })
                                }}>
                                <Button className="selected-date-range-btn mx-1" style={buttonStyle}>
                                    <div className="pull-left">
                                        <i className="fa fa-calendar" />
                                    &nbsp;<span>{label}</span>
                                    </div>
                                    <div className="pull-right">
                                        <i className="fa fa-angle-down" />
                                    </div>
                                </Button>
                            </DatetimeRangePicker>
                            <Button className="selected-date-range-btn mx-1" style={buttonStyle} onClick={() => this.downloadToExcel()}>
                                <i className="fa fa-cloud-download" />&nbsp;Выгрузить
                        </Button>
                        </div>

                        <Form.Check type="switch" id="chbShowShipped1" className="mx-1" >
                            <Form.Check.Input type="checkbox" onChange={this.handleShowShippedChange} />
                            <Form.Check.Label>Показать отгруженные</Form.Check.Label>
                        </Form.Check>
                    </Col>
                    <Col md={{ span: 3 }}>
                        <Aggregate items={this.getRecords()} isAdmin={permissions(user.username).isAdmin} month={this.state.startDate} />
                    </Col>
                </Row>
            </div>
            {
                this.props.records.loading && <div
                    style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
                </div>
            }
            <ReactTable data={this.getRecords()} columns={columns} size="sm"
                className='-striped -highlight' filterable resizable
                getTrProps={this.getTrProps}
                defaultFilterMethod={(filter, rows) => matchSorter(rows, filter.value,
                    { keys: ["projectName"] })
                }
                minRows='5'
                SubComponent={row => {
                    console.log('row.original', row.original);

                    return (
                        <div>
                            <ul className="list-group">
                                {row.original.talks
                                    .map(
                                        (i,idx) => (i.sessionId > 0 ?
                                            <SverkaSubRow row={i} key={i.sessionId}
                                                editpermissions={{ isAdmin: permissions(user.username).isAdmin }} /> :
                                            <SverkaSubRowBA row={i}
                                                filename={`${row.original.phone}_${row.original.projectName}_${moment(row.original.callDate).format("YYYY_MM_DD_hh_mm")}`}

                                                key={i.sessionId}
                                        editpermissions={{ isAdmin: permissions(user.username).isAdmin }} />))}
                            </ul>
                        </div>
                    )
                }}
            />
            <ModalSverka
                show={this.state.modalShow}
                onCancel={() => this.setModalShow(false)}
                onHide={() => this.setModalShow(false)}
                onSave={(l) => {
                    console.log('l', l);
                    this.props.updateRecordsSverka(l);

                    this.props.getRecords();
                    this.setModalShow(false);
                }}
                editpermissions={{ isAdmin: permissions(user.username).isAdmin }}
                lead={this.state.lead}
            />
        </div >);
    }

    downloadToExcel() {

        /* convert state to workbook */
        const data = this.getRecords();
        console.log('data', data.map(x => {
            return {
                callDate: new Date(x.callDate).toLocaleString()
            }
        }));
        const ws = XLSX.utils.json_to_sheet(data.map(x => {
            return {
                phone: x.phone,
                callDate: new Date(x.callDate),
                project: x.projectName,
                comment: x.comment,
                status: x.status,
                commentImetik: x.commentImetik,
                commentPartner: x.commentPartner
            }
        }));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Лист1");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, `imetik_${formatDate(new Date())}.xlsx`);
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            minutes = d.getMinutes();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        if (hour.length < 2)
            hour = '0' + hour;
        if (minutes.length < 2)
            minutes = '0' + minutes;

        return [year, month, day, hour, minutes].join('_');
    }
}


function mapState(state) {
    const { authentication, records } = state;
    const { user } = authentication;
    return { user, records };
}

const actionCreators = {
    getRecords: recordsActions.getRecordsSverka,
    updateRecordsSverka: recordsActions.updateRecordsSverka
}

const connectedSverka = connect(mapState, actionCreators)(Sverka);
export { connectedSverka as Sverka };