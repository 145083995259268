import React from 'react';
import { fancyTimeFormat, formatDate } from '../_helpers'
import './SverkaSubRow.css'
import { connect } from 'react-redux';
import { subRowActions } from '../_actions'

import { Button } from 'react-bootstrap';

class SverkaSubRow extends React.Component {

    componentDidMount() {
        if (this.props.editpermissions.isAdmin)
            this.props.getHiddens(this.props.row.sessionId);
    }

    render() {


        const isAdmin = this.props.editpermissions.isAdmin;
        const row = this.props.row;
        const sessionId = row.sessionId;
        const { hiddens } = this.props;

        const hiddensStr = (isAdmin && hiddens && hiddens[sessionId] && hiddens[sessionId].length > 0) ? hiddens[sessionId].map(x => x.recordId) : [];

        return (<li key={row.sessionId} className="list-group-item">
            Начало разговора:<span>{formatDate(new Date(row.startTime))}</span>&nbsp;
        Продолжительность:<span>{fancyTimeFormat((new Date(row.finishTime) - new Date(row.startTime)) / 1000)}</span>
            <div className="list-group">
                {(row.records ?
                    row.records.split(",")
                        .map(s => ({ record: s, isHidden: false }))

                    : [])
                    .concat(hiddensStr.map(h => { return { record: h, isHidden: true } }))
                    .map((s, index) => {
                        let record = s.record;
                        let isHidden = s.isHidden;
                        return record && <div key={record} className="list-group-item"><audio
                            key={`${row.sessionId}_${record}`}
                            controls
                            src={`https://app.uiscom.ru/system/media/talk/${row.sessionId}/${record}/`}>
                            Your browser does not support the
                                <code>audio</code> element.</audio>

                            {isAdmin && <Button
                                key={`btn_${row.sessionId}_${record}`}
                                className="btnHide"
                                variant={(isHidden) ? 'success' : 'danger'}
                                onClick={(e) => {
                                    this.props.setVisibility(
                                        row.sessionId,
                                        record,
                                        isHidden
                                    );
                                }}>
                                {(isHidden) ? 'Показать' : 'Скрыть'}

                            </Button>
                            }
                        </div>
                    }
                    )}
            </div>
        </li>
        );
    }
}

function mapState(state) {
    const { hiddens } = state.subRow;
    return { hiddens };
}

const actionCreators = {
    getHiddens: subRowActions.getHiddens,
    setVisibility: subRowActions.setVisibility,
};

const connectedSverkaSubRow = connect(mapState, actionCreators)(SverkaSubRow);
export { connectedSverkaSubRow as SverkaSubRow };